import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/SEO/SEO";
import Remedy from "../components/shop/remedy/Remedy";
import ProductInfo from "../views/shop/products/ProductInfo/ProductInfo";
import greenCircle from "../assets/vectors/green-circle-big.svg";
import {StaticImage} from "gatsby-plugin-image";
import ProductMarkupSchema from "../components/common/SEO/ProductMarkupSchema";

const RemedyPage = () => {
    return (
        <Layout>
          <ProductMarkupSchema slug={"preparado-floral"} name={"Preparado Floral de Bach"} description={"Siguiendo la filosofía del Dr. Bach, ponemos a tu disposición las 38 Flores de Bach para que puedas elaborar tu propia fórmula floral."} price={"15"} />
          <SEO
                title="Preparado Floral de Bach"
                description="Siguiendo la filosofía del Dr. Bach, ponemos a tu disposición las 38 Flores de Bach para que puedas elaborar tu propia fórmula floral."
                canonical={"preparado-floral"}
            />
          <div className={"container"}>
            <section className={"grid-x2"}>
              <div className={"m-hero__remedy"}>
                <img
                  src={greenCircle}
                  className={"a-circle-rotate-custom"}
                  alt={"Círculo"}
                />
                  <StaticImage
                    src={"../assets/images/remedio.png"}
                    alt={"Comprar ahora flores de Bach"}
                    className={"a-remedy-image__img"}
                  />
              </div>
              <Remedy title={"Preparado Floral Personalizado"} is_rescue={false} />
            </section>
          </div>

            <ProductInfo data={data} />
            {/*<Brands />*/}
            {/*<Reviews />*/ }
        </Layout>
    );
};

const data = {
  info: [
    "Las Flores de Bach se basan en un sistema floral completo compuesto por 38 remedios de origen natural procedentes de flores, plantas y agua de manantial, que fue desarrollado por el Dr. Edward Bach, reconocido médico, bacteriólogo y patólogo, en Inglaterra. \n",
    "Los remedios se realizan siguiendo unas estrictas normas de elaboración, utilizando el método de solarización para unas y el método de ebullición para otras, tal y como estableció el Dr. Bach. \n",
    "Cada una de las 38 Flores de Bach Originales corresponden a distintos estados anímicos, sentimientos o emociones y actúan potenciando la virtud contraria al estado negativo que sentimos, de esta forma nos ayudan a recuperar el equilibrio emocional de forma suave y segura. \n",
    "El preparado floral se basa en una combinación de diferentes Flores de Bach Originales a tu elección, hasta un máximo de 7 por frasco, diluidas en agua mineral de calidad y conservante alcohólico. Es recomendable retirar el conservante alcohólico para casos de animales, bebés y embarazadas.\n"
  ],
  composition: [
    "Disolución acuosa.",
    "Flores de Bach Originals.",
    "Conservante alcohólico (opcional).",
    "Frasco 30 ml.",
    "Cuentagotas con precinto."
  ],
  administration: [
    "Para tomar el preparado floral es muy sencillo, solo tenemos que poner 4 gotas debajo de la lengua o diluirlas en un vaso de agua y beberse el vaso entero, al menos 4 veces al día.",
    "Recomendamos tomar como mínimo 4 gotas, 4 veces al día; por ejemplo, 4 gotas por la mañana, 4 gotas al mediodía, 4 gotas por la tarde y 4 gotas por la noche; aunque si lo necesitamos, podemos hacer más tomas, pero siempre aumentando el número de tomas y no la cantidad de gotas.",
    "Si al hacer la toma, el cuentagotas entra en contacto con la saliva, debemos limpiarlo antes de introducirlo de nuevo en el frasco, para evitar que se estropee.",
    "La cantidad de alcohol añadida es la mínima necesaria para que actúe como conservante, además la proporción de alcohol en las gotas es muy baja como para hacer un efecto indeseado. Si aún así no estás segura sobre si deberías de tomarlo, te aconsejamos que siempre, ante la duda, lo consultes con tu médico habitual.",
    "También recomendamos no exponer el remedio a altas temperaturas o exponerlo directamente al sol para mantener la calidad. En el caso de no añadirle conservante alcohólico, aconsejamos mantener el frasco en frío en la nevera, para asegurar la correcta conservación y evitar que se estropee el remedio.",
    "Recuerda que toda esta información la recibirás por escrito, al realizar la compra, y recibir tu preparado floral personalizado en tu domicilio, pero ante cualquier cuestión, no dudes en contactarnos."
  ]
}

export default RemedyPage;
